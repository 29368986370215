.App {
  text-align: center;
  color: #333;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ccc;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.nav ul { 
  border: 1px solid #ccc; 
  list-style: none; 
  margin: 0; 
  padding: 1vh 5vw; 
  display: flex; 
  background-color: rgba(235, 235, 135, 0.5);
}

.nav li {
  flex: 1; 
  text-transform: uppercase;
}

.nav a {
  text-decoration: none;
  color: #333;
}

.nav a:hover { 
  background: rgba(255, 255, 255, 0.5); 
}
/*
.nav > a { 
  color: white; 
  font-weight: 300; 
  letter-spacing: 2px; 
  text-decoration: none; 
  background: rgb(238, 13, 13); 
  padding: 20px 5px; 
  display: inline-block; 
  width: 100%; 
  text-align: center; 
  }
  
.nav > a:hover { 
  background: rgba(238, 13, 13, 0.75); 
}
*/
.content {
  margin: 0 10vw;
}

